* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* div.scrollmenu {
  background-color: #333;
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu {
  background-color: #777;
} */

.head_line {
  color: aliceblue;
  font-weight: bold;
  font-size: 10px;
  margin-top: 100px;
}

.mainDiv {
  padding-top: 6%;
  height: 100%;
  width: 100%;
}
.invoice {
  margin-left: 8%;
  margin-top: 2%;
  width: 30%;
  height: 38%;
}
.invoiceFont {
  font-size: 80px;
}
.invoiceNum {
  width: 40%;
  height: 40%;
  float: left;
  margin-left: 4%;
  margin-top: -9%;
}
.invoiceDate {
  font-size: 18px;
  color: #696969;
}
.num {
  margin-top: -4%;
  font-weight: bold;
  font-size: 16px;
}
.Business {
  width: 100%;
  height: 40%;
}
.Buyer {
  width: 30%;
  height: 100%;
  float: left;
  margin-left: 9%;
}
.supplier {
  margin-top: 3%;
}
.buyerHeading {
  color: #696969;
  font-weight: bold;
}
.buyerfont {
  font-weight: bold;
  font-size: 18px;
}
.margin {
  margin-top: -2%;
}
.padding {
  padding-top: 4%;
  padding: 7px 0;
  font-size: 16px;
  margin: 0 25px;
  text-decoration: none;
  color: #181818;
  white-space: nowrap;
  transition: 0.3s all ease-in-out;
}
.totalAmount {
  width: 100%;
  margin-top: 2%;
  /* background-color:red; */
  height: 30%;
  margin-bottom: 10%;
}
.amountFont {
  margin-left: 7%;
  font-size: 29px;
  font-weight: bold;
}
.line {
  height: 1px;
  background: grey;
}
.subTotal {
  margin-left: 80%;
  width: 20%;
  height: 40%;
  margin-top: -5%;
}
.discount {
  font-weight: bold;
  font-size: 16px;
}
.mobileShow {
  display: block;
  width: 10%;
  height: 5%;
  position: fixed;
  bottom: 2%;
  left: 40%;
  margin: 0 0 0 -20%;
  background-color: #5380be;
  color: #ffffff !important;
  transition: 0.1s all linear;
  border-radius: 5px;
}
.reject {
  display: block;
  width: 10%;
  height: 5%;
  position: fixed;
  bottom: 2%;
  left: 60%;
  margin: 0 0 0 -20%;
  background-color: #5380be;
  color: #ffffff !important;
  transition: 0.1s all linear;
  border-radius: 5px;
}

.back {
  display: block;
  width: 10%;
  height: 5%;
  position: fixed;
  bottom: 2%;
  left: 80%;
  margin: 0 0 0 -20%;
  background-color: #5380be;
  color: #ffffff !important;
  transition: 0.1s all linear;
  border-radius: 5px;
}

.button {
  cursor: pointer;
  /* display: block; */
  font-family: "Poppins", sans-serif;
  font-size: 12px !important;
  font-weight: 400;
  height: 40px;
  border-radius: 6px;
  text-transform: uppercase;
  border: 2px solid #5380be;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  padding: 0 20px !important;
  min-width: 120px;
  -webkit-user-select: none;
  user-select: none;
  background-color: #5380be;
  color: #ffffff !important;
  transition: 0.1s all linear;
}

.grid-container {
  width: 50%;
  margin-left: 8%;
  display: grid;
  grid-template-columns: auto auto;
  /* background-color: #2196F3; */
  /* padding: 10px; */
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 20px;
  /* font-size: 30px; */
  text-align: left;
}

.resend-action-button {
  cursor: pointer;
  /* display: block; */
  width: 33px;
  height: 33px;
  margin-right: 10px;
  padding-top: 0px;
}
.pdf-action-button {
  cursor: pointer;
  /* display: block; */
  width: 30px;
  height: 26px;
  margin-right: 10px;
  padding-top: 0px;
}
.action-icon-display {
  display: flex;
}
.p-text {
  margin-top: 15px;
}
.button-text {
  padding-top: 7px;
  text-align: center;
}
.tcp-download-btn {
  position: absolute;
  right: 200px;
}

.download-tag {
  display: flex;
  position: relative;
}
.tcp-download-icon {
  height: 20px;
  display: inline-block !important;
  padding-top: 6px;
}
.selectBusiness {
  width: 50% !important;
  padding: 12px;
  display: flex;
}
.selectOption {
  padding-left: 10px;
  width: 250px;
}
.select-label {
  padding: 6px;
  font-size: 14px;
}

.add-business-input {
  height: 15px !important;
  /* marsgin-left: 0px !important; */
  width: 14px !important;
}

.add-business {
  display: inline !important;
  padding-left: 10px;
}

.label-gstin {
  color: black !important;
  margin-top: 10px;
}

.renew-button {
  margin-left: auto;
  margin-top: 10px;
  background-color: #5380be;
  color: #ffffff !important;
  transition: 0.1s all linear;
  max-width: 150px;
  line-height: 30px;
  height: 34px;
  cursor: pointer;
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 12px !important;
  font-weight: 400;
  height: 40px;
  border-radius: 6px;
  text-transform: uppercase;
  border: 2px solid #5380be;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  padding: 0 20px !important;
  min-width: 120px;
  -webkit-user-select: none;
  user-select: none;
}

.pay-amount-information {
  font-size: 10px;
  line-height: 1.3;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: -webkit-fill-available;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  height: 45px;
  line-height: 1;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  overflow-y: scroll;
  height: 400px;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers tbody {
  text-align: center;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #d0d0ce;
  text-align: center;
}

.select-tcp {
  margin-left: 20px;
  height: 34px;
  max-width: min-content;
  margin-top: 10px;
}
.totalOfBcm {
  margin-left: 16px;
  font-size: 18px;
}

.formCirDiv {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding-left: 25%;
}
.inputSubmit {
  width: 70%;
  background-color: #5380be;
  color: #ffffff !important;
  transition: 0.1s all linear;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.inputText {
  width: 70%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.form-control {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.txn-date-input {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 5px 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 190px;
}

.varLink {
  cursor: pointer;
}
.varLink:hover {
  color: rgb(57, 57, 187);
}
.varLinkError {
  cursor: pointer;
  color: red;
}
.email-div {
  float: left;
  text-align: center;
}

.sms-div {
  float: left;
  margin-left: 35px;
  text-align: center;
}
.icon-icon {
  font-size: 24px !important;
}

/*generate otp css */
.otp-div {
  margin-left: 20px;
}
/* togle button */
.toggle-div {
  float: right;
  margin-right: 38%;
}
.toggle {
  position: relative;
  display: inline-block;
  width: 62px;
  height: 30px;
  background-color: rgb(175, 170, 170);
  border-radius: 30px;
  border: 2px solid gray;
}

/* After slide changes */
.toggle:after {
  content: "";
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: rgb(248, 244, 244);
  top: 2px;
  left: 2px;
  transition: all 0.5s;
}

/* Checkbox checked effect */
.checkbox:checked + .toggle::after {
  left: 34px;
}

/* Checkbox checked toggle label bg color */
.checkbox:checked + .toggle {
  background-color: rgb(118, 127, 211);
}

/* Checkbox vanished */
.checkbox {
  display: none;
}

.otp-input-box {
  width: 20px !important;
  margin: 8px;
}
.flex-otp {
  display: flex;
}
.otp-td-width {
  width: 24%;
}
.toggle-td-width {
  width: 30%;
}

.set-admin-button {
  margin-top: 15px;
}
.set-limit {
  width: 40%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.set-limit:hover {
  border: 1px solid rgb(119, 119, 192);
}
.set-limit-button {
  margin-left: 2%;
  min-width: 100px !important;
  display: initial !important;
  padding: 0 10px !important;
}
.set-limit-div {
  margin-top: -6px;
}
.set-limit-text {
  padding-top: 10px;
}
.set-limit-rupee-symbol {
  position: absolute;
  padding-top: 1%;
  padding-left: 7px;
  font-size: 15px;
}
.set-limit-rupee-symbol-admin {
  position: absolute;
  padding-top: 2.6%;
  padding-left: 8px;
  font-size: 18px;
}
.set-limit-admin {
  width: 50% !important;
}
.set-limit-button-admin {
  position: absolute;
  left: 56%;
  top: 10%;
}

.ticketForm {
  position: fixed;
  bottom: 2.2%;
  right: 1.2%;
  display: block;
  /* width: 4%;
          height: 60px; */
  width: 4.7%;
  height: 68px;
  cursor: pointer;
}

.ticketFormText {
  position: fixed;
  bottom: 4.2%;
  right: 6%;
  color: black;
  background-color: #f7ed66;
  border-radius: 34px;
  padding: 6px;
  font-size: 13px;
  display: block;
  font-weight: bold;
}

.ticketForm:hover {
  width: 4.7%;
  height: 68px;
}

.serviceCharegesDiv {
  display: flex;
  width: 90%;
  margin-left: 5%;
}
.serviecesChragesFieldsDiv {
  margin: 7px;
  width: 40%;
  margin-left: 7%;
}
.remarksDiv {
  background-color: white;
  padding: 4px;
  border-radius: 7px;
  border: 1px outset;
  position: relative;
}
.viewAllRemark {
  font-size: 9px;
  color: blue;
  position: absolute;
  display: flex;
  right: 11px;
  bottom: -8px;
}
.prefields {
  background-color: #d8d1be !important;
}
.submitServiceButton {
  margin-left: 12%;
  margin-top: 30px;
  margin-bottom: 7%;
}
.addRemark {
  position: absolute;
  top: 5px;
  cursor: pointer;
}
.addRemarkIcon {
  width: 18px;
  cursor: pointer;
}

.addRemarkText {
  margin-left: 25px;
  font-size: 11px;
  background-color: rgb(146, 167, 180);
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 15px;
  display: none;
}
.addRemark:hover + .addRemarkText {
  display: inline;
}

.viewRemarkIcon {
  width: 20px;
  cursor: pointer;
}
.viewIconText {
  display: inline;
}
.viewRemarkIcon:hover + .viewIconText {
  display: inline;
}

.textArea {
  width: 100%;
  height: 125px;
  padding: 5px;
  line-height: 18px;
  font-size: 14px;
  font-family: arial, sans-serif;
  border-radius: 10px;
  margin-top: 10px;
  color: #5f6368;
  font-weight: bold;
}

.addRemarkButton {
  display: flex;
  padding: 10px;
  margin-top: 10px;
}
.cancelButton {
  margin-left: 20px;
}
.uploadPaymentFile {
  background-color: white;
  height: 42px;
  padding: 11px;
  width: 100%;
  border-radius: 5px;
  color: blue;
}

.formControl {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.textArea {
  height: 100px !important;
  min-height: auto !important;
}

.serviceCharegesDiv {
  display: flex;
  width: 80%;
  margin-left: 10%;
}
.serviceCharegesDivInnerDiv {
  width: 42%;
  margin-left: 6%;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}
.supplierExpiryWarnningDiv {
  text-align: center;
  padding-top: 10px;
  font-size: 16px;
  font-weight: bold;
}
.innerCard-agreement {
  /* justify-content: left; */
  text-align: justify;

  /* width: 45%; */
}
.agreementBox {
  display: inline-flex;
  margin-left: 40%;
}
.businessDetailsHeader {
  display: inline-flex;
  margin-left: 20%;
}
.selectFile {
  border: 1px solid #7fd6ff;
  height: 20%;
  width: 30%;
  padding: 10px;
  background: linear-gradient(135deg, #abb2b6, #52bdfc);
  border-radius: 10px;
}
.selectFileForNewGST {
  border: 1px solid #7fd6ff;
  height: 20%;
  width: 30%;
  padding: 10px;
  background: linear-gradient(135deg, #abb2b6, #fc6b52);
  border-radius: 10px;
}
.boxUpload {
  width: 64%;
  height: 19%;
  padding: 18px;
}

.exportRequestBox {
  display: inline;

  padding: 10px;
  margin: 10px;
}

.inner-gstBulk {
  width: 120%;
  height: 20%;
  display: inline-flex;
}
.inner-gstBulk select {
  max-width: 200px;
  margin-left: 10px;
}
.gstHitRefreshBlock button {
  margin: 0px 10px 0px;
}
.uploadFileContainer {
  display: flex;
}
.uploadFileContainer-1 {
  margin-left: 30px;
  width: 600px;
  align-items: center;
  padding: 20px;
}
.radio-container {
  display: inline;
  padding: 70px;
}
.checkmark {
  position: relative;
  height: 20px;
  width: 20px;
  background-color: #eee; /* Background color of the radio button */
  border-radius: 50%;
}

.select-contact-block {
  margin-left: 20px;
  margin-bottom: 10px;
  height: 34px;
  max-width: min-content;
  margin-top: 10px;
}
/* src/DownloadPage.css */
.download-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f5f5f5;
  padding: 20px;
}

h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #333;
}

p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #666;
}

.download-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #0056b3;
}
.info-icon-container {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.info-icon {
  box-sizing: border-box;
  position: relative;
  display: block;
  color: #ffffff;
  transform: scale(var(--ggs, 1));
  width: 19px;
  height: 19px;
  border: 2px solid;
  border-radius: 40px;
  cursor: pointer;
  background-color: #5380be;
}
.info-icon::after,
.info-icon::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  background: currentColor;
  left: 7px;
}
.info-icon::after {
  bottom: 2px;
  height: 8px;
}
.info-icon::before {
  height: 2px;
  top: 2px;
}
.tooltip-text {
  visibility: hidden;
  width: 220px; /* Adjust width as needed */
  background-color: #554f4f;
  color: #fff;
  text-align: justify;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the icon */
  left: 50%;
  margin-left: -125px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 10px !important;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%; /* Bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #dacaca transparent transparent transparent;
}

.info-icon-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.button-55 {
  background-color: #5380be;
  border-radius: 15px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 14px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-55:hover {
  box-shadow: rgba(142, 174, 221, 0.749) 0 -25px 18px -14px inset,
    rgba(142, 174, 221, 0.749) 0 1px 2px, rgba(142, 174, 221, 0.749) 0 2px 4px,
    rgba(142, 174, 221, 0.749) 0 4px 8px, rgba(142, 174, 221, 0.749) 0 8px 16px,
    rgba(142, 174, 221, 0.749) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}
/* Modal container */
/* .ReactModal__Content {
  position: relative;
  width: 400px;
  margin: auto;
  padding: 20px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
} */

/* Close button */
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Modal heading */
h1 {
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

/* Button group */
.button-group {
  display: flex;
  justify-content: center;
  gap: 30px;
}

/* Confirm button */
.confirm-button {
  background-color: #4d76b6;
  color: white;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.confirm-button:hover {
  background-color: #617aa2;
}

/* Cancel button */
.cancel-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.cancel-button:hover {
  background-color: #c82333;
}
.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 05px;
  padding: 5px;
  border-radius: 25px;
  background-color: #f0f0f0; /* Light grey background for the bar */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  width: 100%; /* Full width of the container */
  max-width: 400px; /* Limit maximum width */
}

.search-input {
  padding: 10px 15px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  background-color: #ffffff; /* White background for the input */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: all 0.3s ease;
}

.search-input::placeholder {
  color: #aaa; /* Lighter color for placeholder text */
}

.search-input:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(63, 81, 181, 0.5); /* Focus shadow with a blue tint */
}

/* Container div styling */
.agreement-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Paragraph styling for agreement status */
.agreement-status {
  color: red; /* Default color, overridden dynamically */
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

/* Button styling */
.sign-agreement-btn {
  margin: 20px auto;
  display: inline-block;
}
.wallet-transaction-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transaction-info {
  flex: 1;
}

.transaction-type {
  font-size: 16px;
  font-weight: bold;
  color: #4caf50; /* Green for CREDIT */
}

.transaction-type.debit {
  color: #f44336; /* Red for DEBIT */
}

.transaction-amount {
  font-size: 18px;
  font-weight: bold;
  margin-left: 8px;
}

.transaction-date,
.transaction-particulars {
  font-size: 14px;
  color: #777777;
}

.wallet-icon {
  margin-right: 10px;
}
.wallet-container {
  padding: 20px;
}

.wallet-balance-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.wallet-details h5 {
  margin: 10px 0;
}

.active-balance {
  color: green;
  font-weight: bold;
}

.inactive-balance {
  color: red;
  font-weight: bold;
}

.wallet-search input {
  padding: 8px;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.wallet-transaction-card {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;
}

.wallet-transaction-card:hover {
  background-color: #eef;
  cursor: pointer;
}

.transaction-info {
  display: flex;
  justify-content: space-between;
}

.transaction-type {
  font-weight: bold;
  cursor: pointer;
}

.transaction-date,
.transaction-amount {
  font-size: 0.9em;
}

.transaction-particulars {
  margin-top: 10px;
  color: #555;
}

.skeleton-loader {
  background-color: #eee;
  height: 20px;
  margin-bottom: 10px;
  border-radius: 4px;
}
.wallet-card {
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  margin-left: auto;
  margin-right: auto;
}

.card-header {
  color: #fff;
  padding: 10px 15px;
  border-radius: 8px 8px 0 0;
  text-align: center;
}

.card-body {
  padding: 15px;
}

.wallet-transaction-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0;
}

.card {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user-details-card {
  background-color: #f9f9f9;
}

.wallet-balance-card {
  background-color: #f1f1f1;
}

h5 {
  margin-bottom: 15px;
  font-size: 1.25rem;
  font-weight: 600;
}

.user-info,
.wallet-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.active-balance {
  color: #4caf50; /* Green */
}

.inactive-balance {
  color: #f44336; /* Red */
}
.business-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.business-table th,
.business-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.business-table th {
  background-color: #f2f2f2;
}

.business-table td {
  vertical-align: middle;
}

.business-table .btn {
  padding: 5px 10px;
}
.details-section {
  padding: 20px;
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  margin-top: 10px;
}

.details-item h4 {
  margin-bottom: 5px;
  font-weight: 600;
  color: #333;
  padding: 10px;
}

.details-item p {
  margin: 0;
  font-size: 14px;
  color: #555;
}

.evidence-section {
  margin-top: 30px;
}

.evidence-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.evidence-icon {
  cursor: pointer;
  width: 25px;
  height: 25px;
  transition: transform 0.2s ease;
}

.evidence-icon:hover {
  transform: scale(1.1);
}

h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #000; /* Adjust to match your theme */
}

h2 {
  font-size: 18px;
  margin-bottom: 10px;
}
.notification {
  /* Sky blue color */

  text-align: center;
  /* position: fixed; */
  display: ruby;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  font-size: 12px;
  font-weight: bold;
}

.notification.hidden {
  display: none;
}

.dynamic-width-input {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
  display: inline-block;
  min-width: 50px;
}

.input-wrapper {
  display: inline-block;
  position: relative;
}

.hidden-span {
  visibility: hidden;
  white-space: pre;
  font-size: 16px;
  padding: 5px 10px;
  position: absolute;
}
.wallet-transaction {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  margin-bottom: 10px; /* Space between transactions */
}

.wallet-desktop-heading {
  display: inline-block;
  min-width: 80px; /* Set a smaller width for S.No */
  font-weight: bold;
  text-align: left;
}

.wallet-desktop-heading.wide {
  min-width: 150px; /* Wider width for other headings */
}

.purchase-refund-box {
  display: flex;
  align-items: center; /* Align items in the box */
}

.transaction-type {
  margin-left: 10px; /* Space between type and heading */
}

.classic-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0; /* Add some margin */
}

.classic-table th,
.classic-table td {
  border: 1px solid #ddd; /* Light gray border */
  padding: 8px; /* Padding for cells */
  text-align: left; /* Left-aligned text */
}

.classic-table th {
  background-color: #f2f2f2; /* Light gray background for header */
  color: #333; /* Darker text color */
  font-weight: bold; /* Bold header text */
}

.classic-table tr:nth-child(even) {
  background-color: #f9f9f9; /* Zebra striping for even rows */
}

.classic-table tr:hover {
  background-color: #f1f1f1; /* Hover effect for rows */
}

/* BusinessExportForm.css */
.form-container {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.form-header {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

.checkbox-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

.checkbox-item {
  flex: 1 1 45%; /* Allows two items per row */
  display: flex;
  align-items: center;
}

.checkbox-item input {
  margin-right: 10px;
  transform: scale(1.2);
  cursor: pointer;
}

.checkbox-item label {
  font-size: 1rem;
  color: #555;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 10px 15px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  background-color: green;
}

.date-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  background-color: #f9f9f98f;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* margin: 5px; */
}

.date-input-container label {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  font-weight: bold;
}

.date-input-container input[type="date"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #053493b3;
  border-radius: 5px;
  font-size: 14px;
  background-color: #ffffff;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.date-input-container input[type="date"]:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
}
.highlight-row-call-hold-modal {
  background-color: #e3f2fd !important;
  color: #0d47a1;
  font-weight: bold;
  border-left: 4px solid #1e88e5;
}
.highlight-row {
  background-color: #178fe0 !important;
  border-left: 4px solid #00aaff !important;
  transition: background-color 0.3s ease, border-left 0.3s ease;
}

table tr.highlight-row {
  background-color: #add5ef !important;
  border-left: 4px solid #00aaff !important;
}
.custom-table .highlight-row {
  background-color: #add5ef !important;
  border-left: 4px solid #00aaff !important;
}

.highlight-non-eligible-row {
  text-decoration: line-through;
  text-decoration-color: red;
  color: black;
  background-color: #ffd6d6;
}
.supplier-details {
  margin-top: 68px;
  font-size: 14px;
  margin-left: -145px;
}

.supplier-details label {
  font-weight: bold;
}

.supplier-details span {
  margin-right: 15px;
}

.supplier-details p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;
  width: max-content;
}

.supplier-details label {
  font-weight: bold;
  margin-right: 10px;
  width: 150px;
}

.supplier-details span {
  flex-grow: 1;
}

.table-1 {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
  text-align: left;
  overflow: scroll;
}

.table-1 thead tr {
  background: #d3d3d3; /* Light gray for the header */
  color: #333;
}

.table-1 thead th {
  padding: 12px 15px;
  font-weight: bold;
  text-transform: uppercase;
}

.table-1 tbody tr:nth-child(odd) {
  background-color: #e0e0e0; /* Light gray for odd rows */
}

.table-1 tbody tr:nth-child(even) {
  background-color: #ffffff; /* White for even rows */
}

.table-1 tbody td {
  padding: 12px 15px;
  color: black;
}

.table-1 tbody tr:hover {
  background: #e0e0e0; /* Highlighted background when hovered */
}

.table-1 input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
}

.table-1 input:focus {
  border-color: #4caf50;
  outline: none;
}
.canaled-button-red {
  display: inline-block;
  border-radius: 08px;
  background-color: #f4511e;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  padding: 1px;
  width: 108px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 13px;
}

.canaled-button-red span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.canaled-button-red span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.canaled-button-red:hover span {
  padding-right: 25px;
}

.canaled-button-red:hover span:after {
  opacity: 1;
  right: 0;
}
.blinking-text {
  animation: blink 4s infinite;
  color: #af1818;
  font-weight: bold;
}
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.hold-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #b20c0c;
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
  font-weight: bold;
}
.info-container {
  display: flex;
  flex-direction: column;
  gap: 8px; 
}

.info-container p {
  margin: 0; 
}